import {Component, Provide, Vue} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import { GetNoticeApi } from "@/network/modules/common";


@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Notice extends Vue {
  @Provide() loading:boolean = false
  @Provide() titleIndex:number = 0
  @Provide() titleList:Array<{id:number,name:string}> = [{id:1,name:'系统提示'}]
  @Provide() list:Array<{
    noticeContent:string,
    noticeTitle:string,
    releaseTime:string,
    noticeType:number,
  }> = []

  created(){
    const _this:any = this;
    _this.GetNoticeFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/9 5:15 下午
   * @Description: 通知列表
   * @Params: null
   * @Return: null
  */
  async GetNoticeFn(){
    const _this:any = this;
    _this.loading = true
    const res = await GetNoticeApi()
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.list = data
  }

  /**
   * @Author HS
   * @Date 2021/7/9 5:16 下午
   * @Description: 类型切换
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  typeChangeFn(index:number){
    const _this:any = this;
    _this.titleIndex = index
    _this.GetNoticeFn()
  }

}
